import React, { memo } from 'react';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core/styles';

import styles from './styles';
import { Grid } from '@material-ui/core';

const useStyles = makeStyles(styles);

const Footer = () => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <div className={classes.root} style={{ padding: '20px' }}>
      <Grid container justifyContent="center">
        <Grid item xs={12} lg={6} md={6} justifyContent="center">
          <a
            href="https://blog.hashex.org/plateaufinance-smart-contract-audit-report-bb44bf1882c1"
            target="_blank"
          >
            <img
              src="https://hashex.org/static/badges/200x70.svg"
              height="70"
              width="200"
              style={{
                margin: 'auto',
                display: 'block',
                verticalAlign: 'middle',
                position: 'relative',
                transform: 'translateY(-50%)',
                top: '50%',
              }}
            />
          </a>
        </Grid>
        <Grid item xs={12} lg={6} md={6} justifyContent="center">
          <div
            justifyContent="center"
            style={{ display: 'inline', margin: 'auto', cursor: 'pointer' }}
          >
            <a
              href="https://twitter.com/plateau_finance"
              target="_blank"
              item
              style={{
                display: 'inline',
                marginLeft: '20px',
                marginRight: '20px',
                position: 'relative',
                transform: 'translateY(-50%)',
                top: '30%',
                verticalAlign: 'middle',
              }}
            >
              <img src="/twitter.png" style={{ width: '28px', height: '24px' }} />
            </a>

            <a
              href="https://discord.gg/XyR53pfdFh"
              target="_blank"
              item
              style={{
                display: 'inline',
                marginLeft: '20px',
                marginRight: '20px',
                position: 'relative',
                transform: 'translateY(-50%)',
                top: '30%',
                verticalAlign: 'middle',
              }}
            >
              <img src="/discord.png" style={{ width: '36px', height: '29px' }} />
            </a>
            <a
              href="https://plateau-finance.gitbook.io/plateau-finance/"
              target="_blank"
              item
              style={{
                display: 'inline',
                marginLeft: '20px',
                marginRight: '20px',
                position: 'relative',
                transform: 'translateY(-50%)',
                top: '30%',
                verticalAlign: 'middle',
              }}
            >
              <img src="/gitbook.png" style={{ width: '36px', height: '26px' }} />
            </a>
            <a
              href="https://t.me/plateau_fin"
              target="_blank"
              item
              style={{
                display: 'inline',
                marginLeft: '20px',
                marginRight: '20px',
                position: 'relative',
                transform: 'translateY(-50%)',
                top: '30%',
                verticalAlign: 'middle',
              }}
            >
              <img src="/telegram.png" style={{ width: '27px', height: '27px' }} />
            </a>
            <a
              href="https://github.com/Plateau-Finance"
              target="_blank"
              item
              style={{
                display: 'inline',
                marginLeft: '20px',
                marginRight: '20px',
                position: 'relative',
                transform: 'translateY(-50%)',
                top: '30%',
                verticalAlign: 'middle',
              }}
            >
              <img src="/github.png" style={{ width: '32px', height: '31px' }} />
            </a>
            <a
              href="https://plateau-finance.medium.com/"
              target="_blank"
              item
              style={{
                display: 'inline',
                marginLeft: '20px',
                marginRight: '20px',
                position: 'relative',
                transform: 'translateY(-50%)',
                top: '30%',
                verticalAlign: 'middle',
              }}
            >
              <img src="/medium.png" style={{ width: '40px', height: '30px' }} />
            </a>
          </div>
        </Grid>
      </Grid>

      {/* <div className={classes.column}>
        <div className={classes.title}>updates</div>

        <a
          href="https://plateau-finance.gitbook.io/plateau-finance/"
          target="_blank"
          rel="noopener noreferrer"
          className={classes.link}
        >
          <i className={`fas fa-book ${classes.linkIcon}`}></i>
          <span>docs</span>
        </a>

        <a
          href="https://plateau-finance.medium.com/"
          target="_blank"
          rel="noopener noreferrer"
          className={classes.link}
        >
          <i className={`fab fa-medium ${classes.linkIcon}`}></i>
          <span>{t('news')}</span>
        </a>

        <a
          href="https://github.com/Plateau-Finance"
          target="_blank"
          rel="noopener noreferrer"
          className={classes.link}
        >
          <i className={`fab fa-github ${classes.linkIcon}`}></i>
          <span>{t('github')}</span>
        </a>
      </div>

      <div className={classes.column}>
        <div className={classes.title}>{t('socials')}</div>
        <a
          href="https://twitter.com/plateau_finance"
          target="_blank"
          rel="noopener noreferrer"
          className={classes.link}
        >
          <i className={`fab fa-twitter ${classes.linkIcon}`}></i>

          <span>twitter</span>
        </a>
        <a
          href="https://t.me/plateau_fin"
          target="_blank"
          rel="noopener noreferrer"
          className={classes.link}
        >
          <i className={`fab fa-telegram ${classes.linkIcon}`}></i>

          <span>telegram</span>
        </a>
        <a
          href="https://discord.gg/XyR53pfdFh"
          target="_blank"
          rel="noopener noreferrer"
          className={classes.link}
        >
          <i className={`fab fa-discord ${classes.linkIcon}`}></i>
          <span>discord</span>
        </a>
      </div> */}
    </div>
  );
};

export default memo(Footer);
