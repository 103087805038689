export const avalanchePools = [
  {
    id: 'plt-plt',
    name: 'PLT',
    token: 'PLT',
    tokenDescription: 'Plateau Finance',
    tokenAddress: '0xeCfE536a209e405Db19887830b366E397f5B917a',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'PLT-PLT',
    earnedTokenAddress: '0x28873F8dF1a7760D79A64e59B02540DfC95BD0D9',
    earnContractAddress: '0x28873F8dF1a7760D79A64e59B02540DfC95BD0D9',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'tokens',
    oracleId: 'PLT',

    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'Trader Joe',
    assets: ['PLT'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_HIGH',
      'MCAP_MEDIUM',
      'PLATFORM_ESTABLISHED',
      'CONTRACTS_VERIFIED',
    ],
    stratType: 'SingleStake',
    // addLiquidityUrl:
    //   'https://www.traderjoexyz.com/#/pool/AVAX/0xeCfE536a209e405Db19887830b366E397f5B917a',

    buyTokenUrl:
      'https://traderjoexyz.com/#/trade?outputCurrency=0xeCfE536a209e405Db19887830b366E397f5B917a',
  },

  {
    id: 'plt-plt-avax',
    name: 'PLT-AVAX LP',
    token: 'PLT-AVAX LP',
    tokenDescription: 'Plateau Finance',
    tokenAddress: '0x6b3E8eA015e05211D785B032b235F3D358c33f86',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'PLT-PLT-AVAX',
    earnedTokenAddress: '0x51BE4624AE3bBeBB0C729eead21397Fe9F82274f',
    earnContractAddress: '0x51BE4624AE3bBeBB0C729eead21397Fe9F82274f',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'plt-plt-avax',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'Trader Joe',
    assets: ['PLT', 'AVAX'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_HIGH',
      'MCAP_MEDIUM',
      'PLATFORM_ESTABLISHED',
      'CONTRACTS_VERIFIED',
    ],
    stratType: 'StratLP',
    addLiquidityUrl:
      'https://www.traderjoexyz.com/#/pool/AVAX/0xeCfE536a209e405Db19887830b366E397f5B917a',
    buyTokenUrl:
      'https://traderjoexyz.com/#/trade?outputCurrency=0xeCfE536a209e405Db19887830b366E397f5B917a',
  },

  {
    id: 'joe-joe-wavax',
    name: 'JOE-AVAX LP',
    token: 'JOE-AVAX JLP',
    tokenDescription: 'Trader Joe',
    tokenAddress: '0x454E67025631C065d3cFAD6d71E6892f74487a15',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'Joe-JOE-AVAX',
    earnedTokenAddress: '0x7Cd6Aaa2a66624D29f745523e95Cc2FcCfd50aC3',
    earnContractAddress: '0x7Cd6Aaa2a66624D29f745523e95Cc2FcCfd50aC3',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'joe-joe-wavax',
    oraclePrice: 0,
    depositsPaused: true,
    status: 'active',
    platform: 'Trader Joe',
    assets: ['JOE', 'AVAX'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_HIGH',
      'MCAP_MEDIUM',
      'PLATFORM_ESTABLISHED',
      'CONTRACTS_VERIFIED',
    ],
    stratType: 'StratLP',
    addLiquidityUrl:
      'https://www.traderjoexyz.com/#/pool/AVAX/0x6e84a6216eA6dACC71eE8E6b0a5B7322EEbC0fDd',
    buyTokenUrl: 'https://www.traderjoexyz.com/#/trade',
  },

  {
    id: 'joe-joe-usdc.e',
    name: 'JOE-USDC.e LP',
    token: 'JOE-USDC.e JLP',
    tokenDescription: 'Trader Joe',
    tokenAddress: '0x67926d973cD8eE876aD210fAaf7DFfA99E414aCf',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'Joe-JOE-USDC',
    earnedTokenAddress: '0x8A1B8eA5eF2fb62262042f24Ec9055C9BCC759cb',
    earnContractAddress: '0x8A1B8eA5eF2fb62262042f24Ec9055C9BCC759cb',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'joe-joe-usdc.e',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'Trader Joe',
    assets: ['JOE', 'USDCe'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_HIGH',
      'MCAP_MEDIUM',
      'PLATFORM_ESTABLISHED',
      'CONTRACTS_VERIFIED',
    ],
    stratType: 'StratLP',
    addLiquidityUrl:
      'https://www.traderjoexyz.com/#/pool/0x6e84a6216ea6dacc71ee8e6b0a5b7322eebc0fdd/0xa7d7079b0fead91f3e65f86e8915cb59c1a4c664',
    buyTokenUrl: 'https://www.traderjoexyz.com/#/trade',
  },

  {
    id: 'joe-joe-usdt.e',
    name: 'JOE-USDT.e LP',
    token: 'JOE-USDT.e JLP',
    tokenDescription: 'Trader Joe',
    tokenAddress: '0x1643de2efB8e35374D796297a9f95f64C082a8ce',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'Joe-JOE-USDT',
    earnedTokenAddress: '0x623d3DF29b3E113B66f6D96f5851f0B834Fa9f34',
    earnContractAddress: '0x623d3DF29b3E113B66f6D96f5851f0B834Fa9f34',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'joe-joe-usdt.e',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'Trader Joe',
    assets: ['JOE', 'USDTe'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_HIGH',
      'MCAP_MEDIUM',
      'PLATFORM_ESTABLISHED',
      'CONTRACTS_VERIFIED',
    ],
    stratType: 'StratLP',
    addLiquidityUrl:
      'https://traderjoexyz.com/#/pool/0x6e84a6216ea6dacc71ee8e6b0a5b7322eebc0fdd/0xc7198437980c041c805A1EDcbA50c1Ce5db95118',
    buyTokenUrl: 'https://www.traderjoexyz.com/#/trade',
  },

  {
    id: 'joe-wavax-elk',
    name: 'AVAX-ELK LP',
    token: 'AVAX-ELK JLP',
    tokenDescription: 'Trader Joe',
    tokenAddress: '0x88D000E853d03E7D59CE602dff736Dc39aD118fb',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'Joe-AVAX-ELK',
    earnedTokenAddress: '0xa47DA0FC71ee3fB3F1cE67bb225B2Eb0163F3033',
    earnContractAddress: '0xa47DA0FC71ee3fB3F1cE67bb225B2Eb0163F3033',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'joe-wavax-elk',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'Trader Joe',
    assets: ['ELK', 'AVAX'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_LOW',
      'MCAP_MEDIUM',
      'PLATFORM_ESTABLISHED',
      'CONTRACTS_VERIFIED',
    ],
    stratType: 'StratLP',
    addLiquidityUrl:
      'https://www.traderjoexyz.com/#/pool/0xE1C110E1B1b4A1deD0cAf3E42BfBdbB7b5d7cE1C/AVAX',
    buyTokenUrl: 'https://www.traderjoexyz.com/#/trade',
  },

  {
    id: 'joe-wavax-aave.e',
    name: 'AAVE.e-AVAX LP',
    token: 'AAVE.e-AVAX JLP',
    tokenDescription: 'Trader Joe',
    tokenAddress: '0xc3e6D9f7a3A5E3e223356383C7C055Ee3F26A34F',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'Joe-AAVE-AVAX',
    earnedTokenAddress: '0x14df6f0B55cEa9214c74813D93D7Ff849CFf3C51',
    earnContractAddress: '0x14df6f0B55cEa9214c74813D93D7Ff849CFf3C51',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'joe-wavax-aave.e',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'Trader Joe',
    assets: ['AAVEe', 'AVAX'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_LOW',
      'MCAP_MEDIUM',
      'PLATFORM_ESTABLISHED',
      'CONTRACTS_VERIFIED',
    ],
    stratType: 'StratLP',
    addLiquidityUrl:
      'https://www.traderjoexyz.com/#/pool/0x63a72806098bd3d9520cc43356dd78afe5d386d9/AVAX',
    buyTokenUrl: 'https://www.traderjoexyz.com/#/trade',
  },

  {
    id: 'joe-usdc.e-usdt.e',
    name: 'USDC.e-USDT.e LP',
    token: 'USDC.e-USDT.e JLP',
    tokenDescription: 'Trader Joe',
    tokenAddress: '0x2E02539203256c83c7a9F6fA6f8608A32A2b1Ca2',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'Joe-USDC-USDT',
    earnedTokenAddress: '0x763502B69231c9aFefE1F58357401C83b6f89b8d',
    earnContractAddress: '0x763502B69231c9aFefE1F58357401C83b6f89b8d',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'joe-usdc.e-usdt.e',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'Trader Joe',
    assets: ['USDCe', 'USDTe'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_NONE',
      'MCAP_LARGE',
      'PLATFORM_ESTABLISHED',
      'CONTRACTS_VERIFIED',
    ],
    stratType: 'StratLP',
    addLiquidityUrl:
      'https://www.traderjoexyz.com/#/pool/0xa7d7079b0fead91f3e65f86e8915cb59c1a4c664/0xc7198437980c041c805a1edcba50c1ce5db95118',
    buyTokenUrl: 'https://www.traderjoexyz.com/#/trade',
  },

  {
    id: 'sing-plt-avax',
    name: 'PLT-AVAX LP',
    token: 'PLT-AVAX LP',
    tokenDescription: 'Plateau Finance (Singular Farm)',
    tokenAddress: '0x6b3E8eA015e05211D785B032b235F3D358c33f86',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'SING-PLT-AVAX',
    earnedTokenAddress: '0x57aE39e324238942639a8373226DfaB13f8445C4',
    earnContractAddress: '0x57aE39e324238942639a8373226DfaB13f8445C4',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'sing-plt-avax',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'Trader Joe',
    assets: ['PLT', 'AVAX'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_HIGH',
      'MCAP_MEDIUM',
      'PLATFORM_ESTABLISHED',
      'CONTRACTS_VERIFIED',
    ],
    stratType: 'StratLP',
    addLiquidityUrl:
      'https://www.traderjoexyz.com/#/pool/AVAX/0xeCfE536a209e405Db19887830b366E397f5B917a',
    buyTokenUrl:
      'https://traderjoexyz.com/#/trade?outputCurrency=0xeCfE536a209e405Db19887830b366E397f5B917a',
  },

  {
    id: 'sing-sing-usdt.e',
    name: 'SING-USDT.e LP',
    token: 'SING-USDT.e LP',
    tokenDescription: 'TraderJoe (Singular)',
    tokenAddress: '0x42B33De8FAa08F9242F9E20d268FBd251DCEBd72',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'SingUSDT.e-SING',
    earnedTokenAddress: '0xBb1E46e71d794e86AEcF7167Ad0953A5c6D30f98',
    earnContractAddress: '0xBb1E46e71d794e86AEcF7167Ad0953A5c6D30f98',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'sing-sing-usdt.e',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'Other',
    assets: ['SING', 'USDTe'],
    addLiquidityUrl:
      'https://www.traderjoexyz.com/#/pool/0xc7198437980c041c805a1edcba50c1ce5db95118/0xf9a075c9647e91410bf6c402bdf166e1540f67f0',
    buyTokenUrl:
      'https://www.traderjoexyz.com/#/trade?outputCurrency=0xf9a075c9647e91410bf6c402bdf166e1540f67f0',
  },

  {
    id: 'sing-sing-avax',
    name: 'SING-WAVAX LP',
    token: 'SING-WAVAX LP',
    tokenDescription: 'TraderJoe (Singular)',
    tokenAddress: '0xECdD9519f20ba7d4c582CbaA7Db8b3C457906586',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'sing-wavax-sing',
    earnedTokenAddress: '0x6d0Cc388789f3b2c24E230C8FA044a902D96Ca5d',
    earnContractAddress: '0x6d0Cc388789f3b2c24E230C8FA044a902D96Ca5d',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'sing-sing-avax',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'Other',
    assets: ['SING', 'AVAX'],
    addLiquidityUrl:
      'https://www.traderjoexyz.com/#/pool/0xc7198437980c041c805a1edcba50c1ce5db95118/0xf9a075c9647e91410bf6c402bdf166e1540f67f0',
    buyTokenUrl:
      'https://www.traderjoexyz.com/#/trade?outputCurrency=0xf9a075c9647e91410bf6c402bdf166e1540f67f0',
  },

  {
    id: 'sing-sing',
    name: 'SING',
    token: 'SING',
    tokenDescription: 'Singular Finance',
    tokenAddress: '0xF9A075C9647e91410bF6C402bDF166e1540f67F0',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'SING-SING',
    earnedTokenAddress: '0x8b2f36DCaBD8152E0A117E2C1738b3D01961BccE',
    earnContractAddress: '0x8b2f36DCaBD8152E0A117E2C1738b3D01961BccE',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'tokens',
    oracleId: 'SING',

    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'Trader Joe',
    assets: ['SING'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_HIGH',
      'MCAP_MEDIUM',
      'PLATFORM_ESTABLISHED',
      'CONTRACTS_VERIFIED',
    ],
    stratType: 'SingleStake',
    // addLiquidityUrl:
    //   'https://www.traderjoexyz.com/#/pool/AVAX/0xeCfE536a209e405Db19887830b366E397f5B917a',

    buyTokenUrl:
      'https://traderjoexyz.com/#/trade?outputCurrency=0xF9A075C9647e91410bF6C402bDF166e1540f67F0',
  },

  {
    id: 'sing-wmemo',
    name: 'WMEMO',
    token: 'WMEMO',
    tokenDescription: 'Singular Finance',
    tokenAddress: '0x0da67235dD5787D67955420C84ca1cEcd4E5Bb3b',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'SING-WMEMO',
    earnedTokenAddress: '0x6969BEE125369D33dFD1b0F77e831ce228A8dB6b',
    earnContractAddress: '0x6969BEE125369D33dFD1b0F77e831ce228A8dB6b',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'tokens',
    oracleId: 'WMEMO',

    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'Trader Joe',
    assets: ['WMEMO'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_HIGH',
      'MCAP_MEDIUM',
      'PLATFORM_ESTABLISHED',
      'CONTRACTS_VERIFIED',
    ],
    stratType: 'SingleStake',
    // addLiquidityUrl:
    //   'https://www.traderjoexyz.com/#/pool/AVAX/0xeCfE536a209e405Db19887830b366E397f5B917a',

      buyTokenUrl: 'https://traderjoexyz.com/#/trade?outputCurrency=0x0da67235dd5787d67955420c84ca1cecd4e5bb3b',
  },

  //============================================

  {
    id: 'joe-blzz-avax',
    name: 'BLZZ-AVAX LP',
    
    token: 'BLZZ-AVAX LP',
    tokenDescription: 'Trader Joe',
    tokenAddress: '0xac3F978714c613E768272c502a8912bC03DCf624',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'Joe-BLZZ-AVAX',
    earnedTokenAddress: '0x09b21CC165d74E59e29a9DB7D31773Ad276A78EE',
    earnContractAddress: '0x09b21CC165d74E59e29a9DB7D31773Ad276A78EE',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'joe-blzz-avax',
    oraclePrice: 0,
    depositsPaused: true,
    status: 'active',
    platform: 'Trader Joe',
    assets: ['BLZZ', 'AVAX'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_HIGH',
      'MCAP_MEDIUM',
      'PLATFORM_ESTABLISHED',
      'CONTRACTS_VERIFIED',
    ],
    stratType: 'StratLP',
    addLiquidityUrl:
      'https://www.traderjoexyz.com/#/pool/0x6e84a6216ea6dacc71ee8e6b0a5b7322eebc0fdd/0xa7d7079b0fead91f3e65f86e8915cb59c1a4c664',
    buyTokenUrl: 'https://www.traderjoexyz.com/#/trade',
  },

  {
    id: 'joe-wavax-pefi',
    name: 'JOE-PEFI LP',
    token: 'JOE-PEFI LP',
    tokenDescription: 'Trader Joe',
    tokenAddress: '0xb78c8238bD907c42BE45AeBdB4A8C8a5D7B49755',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'Joe-PEFI-AVAX',
    earnedTokenAddress: '0xD487150904AE7e57Be47D04AB45ae82D4BfD95aC',
    earnContractAddress: '0xD487150904AE7e57Be47D04AB45ae82D4BfD95aC',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'joe-wavax-pefi',
    oraclePrice: 0,
    depositsPaused: true,
    status: 'active',
    platform: 'Trader Joe',
    assets: ['PEFI', 'AVAX'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_HIGH',
      'MCAP_MEDIUM',
      'PLATFORM_ESTABLISHED',
      'CONTRACTS_VERIFIED',
    ],
    stratType: 'StratLP',
    addLiquidityUrl:
      'https://www.traderjoexyz.com/#/pool/0x6e84a6216ea6dacc71ee8e6b0a5b7322eebc0fdd/0xa7d7079b0fead91f3e65f86e8915cb59c1a4c664',
    buyTokenUrl: 'https://www.traderjoexyz.com/#/trade',
  },

  {
    id: 'joe-wavax-snob',
    name: 'SNOB-AVAX LP',
    token: 'SNOB-AVAX LP',
    tokenDescription: 'Trader Joe',
    tokenAddress: '0x8fB5bD3aC8eFD05DACae82F512dD03e14aAdAb73',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'Joe-SNOB-AVAX',
    earnedTokenAddress: '0xA38E475f2f6d7B4405f1bE8fea6f86E347002eF3',
    earnContractAddress: '0xA38E475f2f6d7B4405f1bE8fea6f86E347002eF3',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'joe-wavax-snob',
    oraclePrice: 0,
    depositsPaused: true,
    status: 'active',
    platform: 'Trader Joe',
    assets: ['SNOB', 'AVAX'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_HIGH',
      'MCAP_MEDIUM',
      'PLATFORM_ESTABLISHED',
      'CONTRACTS_VERIFIED',
    ],
    stratType: 'StratLP',
    addLiquidityUrl:
      'https://www.traderjoexyz.com/#/pool/0x6e84a6216ea6dacc71ee8e6b0a5b7322eebc0fdd/0xa7d7079b0fead91f3e65f86e8915cb59c1a4c664',
    buyTokenUrl: 'https://www.traderjoexyz.com/#/trade',
  },

  {
    id: 'joe-teddy-wavax',
    name: 'TEDDY-AVAX LP',
    token: 'TEDDY-AVAX LP',
    tokenDescription: 'Trader Joe',
    tokenAddress: '0x91f0963873bbcA2e58d21bB0941c0D859Db3ca31',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'Joe-TEDDY-AVAX',
    earnedTokenAddress: '0x74E13E162AF95b84CE582fEA0d7211ae7A027e8e',
    earnContractAddress: '0x74E13E162AF95b84CE582fEA0d7211ae7A027e8e',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'joe-teddy-wavax',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'Trader Joe',
    assets: ['TEDDY', 'AVAX'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_HIGH',
      'MCAP_MEDIUM',
      'PLATFORM_ESTABLISHED',
      'CONTRACTS_VERIFIED',
    ],
    stratType: 'StratLP',
    addLiquidityUrl:
      'https://www.traderjoexyz.com/#/pool/0x6e84a6216ea6dacc71ee8e6b0a5b7322eebc0fdd/0xa7d7079b0fead91f3e65f86e8915cb59c1a4c664',
    buyTokenUrl: 'https://www.traderjoexyz.com/#/trade',
  },

  {
    id: 'elk-elk-wavax',
    name: 'ELK-AVAX LP',
    token: 'ELK-AVAX LP',
    tokenDescription: 'ELK Finance',
    tokenAddress: '0x2612dA8fc26Efbca3cC3F8fD543BCBa72b10aB59',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'ELK-ELK-AVAX',
    earnedTokenAddress: '0xFa94bC5209d7C7bA857f3AcDdAA11Be3AA66Bdfe',
    earnContractAddress: '0xFa94bC5209d7C7bA857f3AcDdAA11Be3AA66Bdfe',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'elk-elk-wavax',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'ELK Finance',
    assets: ['ELK', 'AVAX'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_HIGH',
      'MCAP_MEDIUM',
      'PLATFORM_ESTABLISHED',
      'CONTRACTS_VERIFIED',
    ],
    stratType: 'StratLP',
    addLiquidityUrl:
      'https://app.elk.finance/#/add/AVAX/0xE1C110E1B1b4A1deD0cAf3E42BfBdbB7b5d7cE1C',
    buyTokenUrl: 'https://app.elk.funance/#/swap',
  },

  {
    id: 'elk-elk-dai',
    name: 'ELK-DAI LP',
    token: 'ELK-DAI LP',
    tokenDescription: 'ELK Finance',
    tokenAddress: '0x27f382534916520f178bf9e201db4CD8172CC7D2',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'ELK-DAI-AVAX',
    earnedTokenAddress: '0x3055e2E64BE1824b29C98a5B2e41936fFcb2f8Fe',
    earnContractAddress: '0x3055e2E64BE1824b29C98a5B2e41936fFcb2f8Fe',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'elk-elk-dai',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'ELK Finance',
    assets: ['ELK', 'DAI'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_HIGH',
      'MCAP_MEDIUM',
      'PLATFORM_ESTABLISHED',
      'CONTRACTS_VERIFIED',
    ],
    stratType: 'StratLP',
    addLiquidityUrl:
      'https://app.elk.finance/#/add/0xbA7dEebBFC5fA1100Fb055a87773e1E99Cd3507a/0xE1C110E1B1b4A1deD0cAf3E42BfBdbB7b5d7cE1C',
    buyTokenUrl: 'https://app.elk.funance/#/swap',
  },

  {
    id: 'elk-elk-usdt',
    name: 'ELK-USDT LP',
    token: 'ELK-USDT LP',
    tokenDescription: 'ELK Finance',
    tokenAddress: '0x1AEb1DeF5B064Df8e4470E57Af17dF72961A9eF8',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'ELK-ELK-USDT',
    earnedTokenAddress: '0x0636820cFE7b80763d640897258c7254Bc67570d',
    earnContractAddress: '0x0636820cFE7b80763d640897258c7254Bc67570d',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'elk-elk-usdt',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'ELK Finance',
    assets: ['ELK', 'USDT'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_HIGH',
      'MCAP_MEDIUM',
      'PLATFORM_ESTABLISHED',
      'CONTRACTS_VERIFIED',
    ],
    stratType: 'StratLP',
    addLiquidityUrl:
      'https://app.elk.finance/#/add/0xde3A24028580884448a5397872046a019649b084/0xE1C110E1B1b4A1deD0cAf3E42BfBdbB7b5d7cE1C',
    buyTokenUrl: 'https://app.elk.funance/#/swap',
  },

  {
    id: 'elk-elk-weth',
    name: 'ELK-WETH LP',
    token: 'ELK-WETH LP',
    tokenDescription: 'ELK Finance',
    tokenAddress: '0xaf91700c900A1c348b277Be5AdCD9458C3007254',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'ELK-ELK-WETH',
    earnedTokenAddress: '0x5724d22aeAba7dB4C126Aa06736a2c43E81426f7',
    earnContractAddress: '0x5724d22aeAba7dB4C126Aa06736a2c43E81426f7',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'elk-elk-weth',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'ELK Finance',
    assets: ['ELK', 'WETH'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_HIGH',
      'MCAP_MEDIUM',
      'PLATFORM_ESTABLISHED',
      'CONTRACTS_VERIFIED',
    ],
    stratType: 'StratLP',
    addLiquidityUrl: 'https://app.elk.finance/#/add/',
    buyTokenUrl: 'https://app.elk.funance/#/swap',
  },

  {
    id: 'elk-elk-plt',
    name: 'PLT-ELK LP',
    token: 'PLT-ELK LP',
    tokenDescription: 'ELK Finance',
    tokenAddress: '0x70CD9aa27b7a6fEe03F9683aAbD742D77B584012',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'ELK-PLT-ELK',
    earnedTokenAddress: '0xDeC6703d0cc18f6533161e3EFe7885cfb15c5016',
    earnContractAddress: '0xDeC6703d0cc18f6533161e3EFe7885cfb15c5016',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'elk-elk-plt',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'ELK Finance',
    assets: ['ELK', 'PLT'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_HIGH',
      'MCAP_MEDIUM',
      'PLATFORM_ESTABLISHED',
      'CONTRACTS_VERIFIED',
    ],
    stratType: 'StratLP',
    addLiquidityUrl: 'https://app.elk.finance/#/add/',
    buyTokenUrl: 'https://app.elk.funance/#/swap',
  },




  {
    id: 'lyd-avax-evrt',
    name: 'EVRT-AVAX LP',
    token: 'EVRT-AVAX LP',
    tokenDescription: 'Lydia Finance',
    tokenAddress: '0x26bbBf5104F99Dd1d6e61fF54980E78edcb0ba29',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'LYDIA-EVRT-AVAX',
    earnedTokenAddress: '0x9934Db9fAf6C0EeA9F060EFf212774887De55195',
    earnContractAddress: '0x9934Db9fAf6C0EeA9F060EFf212774887De55195',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'lyd-avax-evrt',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'lyd Finance',
    assets: ['EVRT', 'AVAX'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_HIGH',
      'MCAP_MEDIUM',
      'PLATFORM_ESTABLISHED',
      'CONTRACTS_VERIFIED',
    ],
    stratType: 'StratLP',
    addLiquidityUrl: 'https://exchange.lydia.finance/#/add/',
    buyTokenUrl: 'https://exchange.lydia.finance/#/swap',
  },



  {
    id: 'lyd-lyd-hct',
    name: 'AVAX-HCT LP',
    token: 'AVAX-HTC LP',
    tokenDescription: 'Lydia Finance',
    tokenAddress: '0x34b839A62149c41678bd1B04Ad0F404c42744728',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'lyd-AVAX-HCT',
    earnedTokenAddress: '0xd3ef586Ee357012b8Ded25D36457C8a3f7E1AE8A',
    earnContractAddress: '0xd3ef586Ee357012b8Ded25D36457C8a3f7E1AE8A',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'lyd-lyd-hct',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'lyd Finance',
    assets: ['HCT', 'AVAX'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_HIGH',
      'MCAP_MEDIUM',
      'PLATFORM_ESTABLISHED',
      'CONTRACTS_VERIFIED',
    ],
    stratType: 'StratLP',
    addLiquidityUrl: 'https://exchange.lydia.finance/#/add/',
    buyTokenUrl: 'https://exchange.lydia.finance/#/swap',
  },



  {
    id: 'lyd-dai-lyd',
    name: 'DAI-LYD LP',
    token: 'DAI-LYD LP',
    tokenDescription: 'Lydia Finance',
    tokenAddress: '0x4EE072c5946B4cdc00CBdeB4A4E54A03CF6d08d3',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'lyd-dai-lyd',
    earnedTokenAddress: '0x7C4E49DF065138728EAd98fb17eABCA9Cdf717c7',
    earnContractAddress: '0x7C4E49DF065138728EAd98fb17eABCA9Cdf717c7',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'lyd-dai-lyd',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'lyd Finance',
    assets: ['DAI', 'LYD'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_HIGH',
      'MCAP_MEDIUM',
      'PLATFORM_ESTABLISHED',
      'CONTRACTS_VERIFIED',
    ],
    stratType: 'StratLP',
    addLiquidityUrl: 'https://exchange.lydia.finance/#/add/',
    buyTokenUrl: 'https://exchange.lydia.finance/#/swap',
  },



  {
    id: 'lyd-joe-avax',
    name: 'AVAX-JOE LP',
    token: 'AVAX-JOE LP',
    tokenDescription: 'Lydia Finance',
    tokenAddress: '0x211e7A5e69c8cd07bbB6dFFe61Ab2680df5c8447',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'lyd-joe-avax',
    earnedTokenAddress: '0x9111190274022E087d1c9325ad35D505dffFB1Eb',
    earnContractAddress: '0x9111190274022E087d1c9325ad35D505dffFB1Eb',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'lyd-joe-avax',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'lyd Finance',
    assets: ['AVAX', 'JOE'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_HIGH',
      'MCAP_MEDIUM',
      'PLATFORM_ESTABLISHED',
      'CONTRACTS_VERIFIED',
    ],
    stratType: 'StratLP',
    addLiquidityUrl: 'https://exchange.lydia.finance/#/add/',
    buyTokenUrl: 'https://exchange.lydia.finance/#/swap',
  },





  {
    id: 'lyd-avax-lyd',
    name: 'AVAX-LYD LP',
    token: 'AVAX-LYD LP',
    tokenDescription: 'Lydia Finance',
    tokenAddress: '0xFba4EdaAd3248B03f1a3261ad06Ad846A8e50765',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'lyd-avax-lyd',
    earnedTokenAddress: '0xBE7d86f90401c642e0261040C38BDa5aD96A36B2',
    earnContractAddress: '0xBE7d86f90401c642e0261040C38BDa5aD96A36B2',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'lyd-avax-lyd',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'lyd Finance',
    assets: ['AVAX', 'LYD'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_HIGH',
      'MCAP_MEDIUM',
      'PLATFORM_ESTABLISHED',
      'CONTRACTS_VERIFIED',
    ],
    stratType: 'StratLP',
    addLiquidityUrl: 'https://exchange.lydia.finance/#/add/',
    buyTokenUrl: 'https://exchange.lydia.finance/#/swap',
  },


  {
    id: 'lyd-lyd-usdt',
    name: 'LYD-USDT LP',
    token: 'LYD-USDT LP',
    tokenDescription: 'Lydia Finance',
    tokenAddress: '0x1718309E2AD61A945FCD242F28Dc83339b5d6192',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'lyd-lyd-usdt',
    earnedTokenAddress: '0x1874549d2467eB35170C7D742f64518cC0539d89',
    earnContractAddress: '0x1874549d2467eB35170C7D742f64518cC0539d89',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'lyd-lyd-usdt',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'lyd Finance',
    assets: ['LYD', 'USDT'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_HIGH',
      'MCAP_MEDIUM',
      'PLATFORM_ESTABLISHED',
      'CONTRACTS_VERIFIED',
    ],
    stratType: 'StratLP',
    addLiquidityUrl: 'https://exchange.lydia.finance/#/add/',
    buyTokenUrl: 'https://exchange.lydia.finance/#/swap',
  },




  {
    id: 'lyd-maxi-lyd',
    name: 'LYD-MAXI LP',
    token: 'LYD-MAXI LP',
    tokenDescription: 'Lydia Finance',
    tokenAddress: '0x50f154742816a1Ab7DEC3689012Ca123e8bA3C5F',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'lyd-maxi-lyd',
    earnedTokenAddress: '0x1d05E70da2Cff16aC2b07F5523c52cC568aeC817',
    earnContractAddress: '0x1d05E70da2Cff16aC2b07F5523c52cC568aeC817',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'lyd-maxi-lyd',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'lyd Finance',
    assets: ['MAXI', 'LYD'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_HIGH',
      'MCAP_MEDIUM',
      'PLATFORM_ESTABLISHED',
      'CONTRACTS_VERIFIED',
    ],
    stratType: 'StratLP',
    addLiquidityUrl: 'https://exchange.lydia.finance/#/add/',
    buyTokenUrl: 'https://exchange.lydia.finance/#/swap',
  },


  {
    id: 'lyd-pefi-lyd',
    name: 'LYD-PEFI LP',
    token: 'LYD-PEFi LP',
    tokenDescription: 'Lydia Finance',
    tokenAddress: '0xCC592739C6c64f797E46CD00f12a6f15c2DF1c04',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'lyd-pefi-lyd',
    earnedTokenAddress: '0x97d1d75BaC7C44b7b5728a5063b4d034C953C88e',
    earnContractAddress: '0x97d1d75BaC7C44b7b5728a5063b4d034C953C88e',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'lyd-pefi-lyd',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'lyd Finance',
    assets: ['PEFI', 'LYD'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_HIGH',
      'MCAP_MEDIUM',
      'PLATFORM_ESTABLISHED',
      'CONTRACTS_VERIFIED',
    ],
    stratType: 'StratLP',
    addLiquidityUrl: 'https://exchange.lydia.finance/#/add/',
    buyTokenUrl: 'https://exchange.lydia.finance/#/swap',
  },




  {
    id: 'lyd-avax-duel',
    name: 'AVAX-DUEL LP',
    token: 'AVAX-DUEL LP',
    tokenDescription: 'Lydia Finance',
    tokenAddress: '0xc70b2E0cB6946BD7EC71d2a3cC810fBDfa425a11',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'lyd-avax-duel',
    earnedTokenAddress: '0x9266900cf87cBd345F13aD8685d4A43e4caB47f7',
    earnContractAddress: '0x9266900cf87cBd345F13aD8685d4A43e4caB47f7',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'lyd-avax-duel',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'lyd Finance',
    assets: ['AVAX', 'DUEL'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_HIGH',
      'MCAP_MEDIUM',
      'PLATFORM_ESTABLISHED',
      'CONTRACTS_VERIFIED',
    ],
    stratType: 'StratLP',
    addLiquidityUrl: 'https://exchange.lydia.finance/#/add/',
    buyTokenUrl: 'https://exchange.lydia.finance/#/swap',
  },



  {
    id: 'lyd-avax-cycle',
    name: 'AVAX-CYCLE LP',
    token: 'AVAX-CYCLE LP',
    tokenDescription: 'Lydia Finance',
    tokenAddress: '0x234285bEf3a95479F3432e1AD2b396b76Bf4E4D2',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'lyd-avax-cycle',
    earnedTokenAddress: '0xf81eFe12f9CD8144E81c72bbEb569f873019D81b',
    earnContractAddress: '0xf81eFe12f9CD8144E81c72bbEb569f873019D81b',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'lyd-avax-cycle',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'lyd Finance',
    assets: ['AVAX', 'CYCLE'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_HIGH',
      'MCAP_MEDIUM',
      'PLATFORM_ESTABLISHED',
      'CONTRACTS_VERIFIED',
    ],
    stratType: 'StratLP',
    addLiquidityUrl: 'https://exchange.lydia.finance/#/add/',
    buyTokenUrl: 'https://exchange.lydia.finance/#/swap',
  },




];
